<template>
    <div class="report-list mb-w-max">
		<div class="my-order-head mb-pad-x-15" style="display: flex;">
			<div class="_pc-font-26 _b display-none" style="flex: 2;">{{ $t('我的包裹') }}</div>
			<div class="search display-none" style="flex: 1;">
				<div class="search-left mb-flex-row-mid">
					<div class="iconfont2 icon-sousuo1"></div>
					<input type="text" :placeholder="$t('搜索包裹关键词')" v-model="thisFrom.keywords">
				</div>
				<div class="_pc-pad-x-15" style="color: #4284DC;border-left: 1px solid #999;font-size: 18px;cursor:pointer;" @click="search">{{ $t('search') }}</div>
			</div>
		</div>
		<div class="head display-none" style="text-align: center;background-color: #FFF;">
			<div style="width:33%;cursor: pointer;" class="time" :style="!thisFrom.status ? 'color:#4284DC' : ''" @click="statusReport('')">{{ $t('all') }}</div>
			<div style="width:33%;border-left: 1px solid #999;border-right: 1px solid #999;cursor: pointer;" @click="statusReport(10)" class="type" :style="thisFrom.status == 10 ? 'color:#4284DC' : ''">{{ $t('待入库') }}</div>
			<div style="width:33%;cursor: pointer;" class="number" :style="thisFrom.status == 20 ? 'color:#4284DC' : ''" @click="statusReport(20)">{{ $t('已入库') }}</div>
		</div>
		<div class="display-none" style="width: 100%;overflow: auto;overflow-x: scroll;">
			<div class="head">
				<div style="width:230px;text-align: center;" class="time">{{ $t('预报时间') }}</div>
				<div style="width:230px;text-align: center;" class="type">{{ $t('物品类型') }}</div>
				<div style="width:250px;text-align: center;" class="number">{{ $t('包裹单号') }}</div>
				<div style="width:200px;text-align: center;" class="number">{{ $t('入库图片') }}</div>
				<div style="width:200px;text-align: center;" class="number">{{ $t('包裹状态') }}</div>
				<div style="width:200px;text-align: center;" class="number">{{ $t('包裹重量') }}</div>
				<div style="width:150px;text-align: center;" class="number">{{ $t('包裹类型') }}</div>
				<div style="width:230px;text-align: center;" class="number">{{ $t('到付金额') }}(RMB)</div>
				<div style="width:230px;text-align: center;" class="number">{{ $t('到付金额') }}(USD)</div>
				<div style="width:250px;text-align: center;" class="number">{{ $t('是否超大包裹') }}</div>
				<div style="width:250px;text-align: center;" class="number">{{ $t('异常原因') }}</div>
				<div style="width:340px;text-align: center;" class="number">{{ $t('报备备注') }}</div>
				<div style="width:340px;text-align: center;" class="number">{{ $t('入库备注') }}</div>
				<div style="width:140px;text-align: center;" class="number">{{ $t('申报价值') }}</div>
				<div style="width:250px;text-align: center;" class="number">{{ $t('木架费') }}</div>
			</div>
			<div class="main">
				<div class="item" v-for="(item,index) in list" :key="index">
					<div style="width:230px;text-align: center;" class="_pc-font-18">{{ item.report_time }}</div>
					<div style="width:230px;line-height: 1.7;text-align: center;" class="_pc-font-18">{{ item.goods_name }}</div>
					<div style="width:250px;text-align: center;" class="_pc-font-18">{{ item.expresssn }}</div>
					<div style="width:200px;" class="_pc-font-18" ><div style="float: left;" v-viewer v-for="(val,indexs) in item.package_imgs" :key="indexs"><img style="width: 40px;height: 40px;" :src="val"></div></div>
					<div style="width:200px;text-align: center;" class="_pc-font-18">{{ item.status_name }}</div>
					<div style="width:200px;text-align: center;" class="_pc-font-18">{{ item.weight }}</div>
					<div style="width:150px;text-align: center;" class="_pc-font-18">
						<span v-if="lang == 'zh_cn'">{{ item.cate_name }}</span>
						<span v-if="lang == 'ru'">{{ item.cate_name_ru }}</span>
						<span v-if="lang == 'en_us'">{{ item.cate_name_en }}</span>
					</div>
					<div style="width:230px;text-align: center;" class="_pc-font-18">{{ item.collect_payment }}</div>
					<div style="width:230px;text-align: center;" class="_pc-font-18">{{ item.price }}</div>
					<div style="width:250px;text-align: center;" class="_pc-font-18">{{ item.is_big_report?$t('yes'):$t('no') }}</div>
					<div style="width:250px;text-align: center;" class="_pc-font-18">
						<span v-if="lang == 'zh_cn'">{{ item.abnormal_cause_zh }}</span>
						<span v-if="lang == 'ru'">{{ item.abnormal_cause_ru }}</span>
						<span v-if="lang == 'en_us'">{{ item.abnormal_cause_en }}</span>
					</div>
					<div style="width:340px;text-align: center;" class="_pc-font-18">{{ item.report_remark }}</div>
					<div style="width:340px;text-align: center;" class="_pc-font-18">{{ item.remark }}</div>
					<div style="width:140px;text-align: center;" class="_pc-font-18">{{ item.goods_money }}</div>
					<div style="width:250px;text-align: center;" class="_pc-font-18">{{ item.reinforce_free }}</div>
				</div>
				<div style="text-align: center;width: 100%;line-height: 100px;font-size: 18;font-weight: bold;" v-if="list.length == 0">~{{$t('暂无数据')}}~</div>
			</div>
		</div>
		<div style="margin-top: 20px;margin-left: 20px;margin-bottom: 10px;">
			<Page :total="total" :current.sync="thisFrom.page" show-total @on-change="pageLogChange" :page-size="thisFrom.limit"/>
		</div>
	</div>
</template>

<script>
    import { reportingRecord } from '@/api/jiyun';
    export default {
        data() {
            return {
                list: [],
				top: 0,
				total: 0,
				thisFrom: {
					domain_url: window.location.origin,
					page: 1,
					limit: 10,
					status: '',
					keywords: '',
				},
				lang: '',
            }
        },
        created() {
            this.lang = localStorage.getItem('think-lang') || 'ru';
			this.thisFrom.keywords = this.$route.query.keywords;
			this.top = this.$route.query.top-0;
            this.getList();
        },
        methods: {
            search(){
				this.thisFrom.page = 1;
				this.getList();
			},
			statusReport(row){
				this.thisFrom.status = row;
				this.getList();
			},
			getList(){
				reportingRecord(this.thisFrom).then((res)=>{
					this.list = res.data.list;
					this.total = res.data.count;
				}).catch((err)=>{
					this.$Message.error(err.msg)
				})
			},
			pageLogChange(index){
				this.thisFrom.page = index;
				this.getList();
			},
        }
    }
</script>

<style scoped>
	.mb-li-container {
		width: 100%;
	}
	.mb-li-container .mb-li {
		width: 100%;
		background: #f5f5f5;
		padding: 0.9375rem;
	}
	.mb-li-container .mb-li .mb-item {
		width: 100%;
		background: #fff;
		padding: 0rem 0.9375rem;
		font-size: 0.875rem;
		box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.3);
		margin-bottom: 1.25rem;
	}
	.mb-li-container .mb-li .mb-item .mb-item-header {
		width: 100%;
		padding: 0.9375rem 0rem;
		font-size: 0.9375rem;
		border-bottom: 1px solid #f5f5f5;
	}
	.mb-li-container .mb-li .mb-item .mb-item-main {
		width: 100%;
		padding: 0.9375rem 0rem;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-row-gap: 0.375rem;
		border-bottom: 1px solid #f5f5f5;
	}
	.mb-li-container .mb-li .mb-item .mb-item-footer {
		width: 100%;
		padding: 0.9375rem 0rem;
		color: #999;
		font-size: 0.75rem;
	}
	.report-list .main {
		/* width: 100%; */
		height: 473px;
		overflow: scroll;
		overflow-x: hidden;
		
	}
	.report-list .main .item {
		/* width: 100%; */
		/* height: 70px; */
		padding: 25px 20px;
		border-bottom: 1px solid #E4E4E4;
		color: #333;
		display: flex;
		align-items: center;
	}
	.report-list .main .item:hover {
		background-color: #F5F5F5;
	}
	.report-list .main .item:last-child {
		border-bottom: none;
	}
	
	@media screen and (min-width: 768px){
		.pc-display-none {
			display: none !important;
		}
		.report-list {
			/* width: 70%; */
			/* height: 1000px; */
			background: #FFFFFF;
			border: 1px solid #D9D9D9;
		}
		.report-list .head {
			width: 100%	;
			height: 55px;
			background: #F5F5F5;
			border-bottom: 1px solid #D9D9D9;
			display: flex;
			align-items: center;
			padding: 0 20px;
			color: #999;
			font-size: 18px;
		}
		.my-order-head {
			width: 100%;
			border-bottom: 1px solid #d8d8d8;
			padding: 30px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			column-gap: 3.75rem;
		}
		.my-order-head .search {
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-radius: 9px;
			background: #f5f5f5;
			padding: 0px 16px;
			flex: 1;
		}
		.my-order-head .search .search-left {
			display: flex;
			align-items: center;
			flex: 1;
		}
		.my-order-head .search .search-left .icon-sousuo1 {
			transform: rotateY(180deg);
			color: #383838;
			font-size: 24px;
			margin-right: 12px;
		}
		.my-order-head .search .search-left input {
			flex: 1;
			height: 52px;
			outline: none;
			background-color: #f5f5f5;
		}
	}
	/*屏幕在480px到768之间（主要是手机屏幕）*/
	@media screen and (max-width: 768px){
		input {
			outline: none;
		}
		.display-none {
			display: none !important;
		}
		.mb-display-block {
			display: block;
		}
		/* pad */
		.mb-pad-0 {
			padding: 0 !important;
		}
		.mb-pad-x-15 {
			padding-left: 0.9375rem !important;
			padding-right: 0.9375rem !important;
		}
		.mb-pad-x-25 {
			padding-left: 1.5625rem;
			padding-right: 1.5625rem;
		}
		.mb-pad-x-30 {
			padding-left: 1.875rem;
			padding-right: 1.875rem;
		}
		.mb-pad-y-15 {
			padding-top: 0.9375rem !important;
			padding-bottom: 0.9375rem !important;
		}
		.mb-pad-y-25 {
			padding-top: 1.5625rem;
			padding-bottom: 1.5625rem;
		}
		.mb-pad-y-30 {
			padding-top: 1.875rem;
			padding-bottom: 1.875rem;
		}
		/* mar */
		.mb-mar-0 {
			margin: 0;
		}
		.mb-mar-top-30 {
			margin-top: 1.875rem;
		}
		.mb-mar-top-50 {
			margin-top: 3.125rem;
		}
		.mb-mar-right-15 {
			margin-right: 0.9375rem;
		}
		.mb-mar-y-15 {
			margin-top: 0.9375rem;
			margin-bottom: 0.9375rem;
		}
		.mb-mar-y-25 {
			margin-top: 1.5625rem;
			margin-bottom: 1.5625rem;
		}
		/* width */
		.mb-w-max {
			width: 100%;
		}
		.mb-w-70 {
			width: 4.375rem;
		}
		.mb-w-100 {
			width: 6.25rem;
		}
		.mb-w-18 {
			width: 1.125rem;
		}
		/* height */
		.mb-h-60 {
			height: 3.75rem;
		}
		/* font */
		.mb-font-15 {
			font-size: 0.9375rem !important;
		}
		.mb-font-20 {
			font-size: 1.25rem;
		}
		.mb-font-25 {
			font-size: 1.5625rem;
		}
		.mb-font-30 {
			font-size: 1.875rem;
		}
		.mb-font-35 {
			font-size: 2.1875rem;
		}
		
		.mb-text-center {
			text-align: center;
		}
		/* flex */
		.mb-flex-1 {
			flex: 1;
		}
		.mb-flex-2 {
			flex: 2;
		}
		.mb-flex-col-center-mid {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}
		.mb-flex-row-bet-mid {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
		}
		.mb-flex-row-mid {
			display: flex;
			flex-direction: row;
			align-items: center;
		}
		.mb-flex-shrink {
			flex-shrink: 0;
		}
		.mb-border-radius-10 {
			border-radius: 0.625rem;
		}
		.mb-border-radius-15 {
			border-radius: 0.9375rem;
		}
		.mb-grid-column-max-span{
			grid-column: 1 / -1;
		}
		.mb-order-1 {order: 1;}
		.mb-order-2 {order: 2;}
		.mb-order-3 {order: 3;}
		.mb-order-4 {order: 4;}
		.mb-order-5 {order: 5;}
		.mb-order-6 {order: 6;}
		.mb-order-7 {order: 7;}
		.mb-order-8 {order: 8;}
		.mb-order-9 {order: 9;}
		.mb-order-10 {order: 10;}
		.mb-min-height-205 {
			min-height: 205px;
		}
		.mb-search-container {
			width: 100%;
			background: #f5f5f5;
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-radius: 0.375rem;
			margin-top: 0.9375rem;
			font-size: 0.9375rem;
			padding: 0.675rem 0;
		}
		.mb-search-container .input-box {
			width: 100%;
		}
		.mb-search-container .input-box label {
			width: 100%;
			display: flex;
			align-items: center;
			flex: 1;
		}
		.mb-search-container .search-btn {
			flex-shrink: 0;
			padding: 0px 0.5rem;
			color: #4284DC;
			border-left: 1px solid;
			border-color: #D9D9D9;
		}
		.mb-search-container .input-box label .icon-sousuo1 {
			transform: rotateY(180deg);
			color: #383838;
			font-size: 1.05rem;
			padding: 0px 0.5rem;
		}
		.mb-search-container .input-box label input {
			background: #f5f5f5;
		}
		.report-list .head {
			margin-top: 0.625rem;
			width: 100%	;
			background: #F5F5F5;
			border-bottom: 1px solid #D9D9D9;
			color: #999;
			display: grid;
			font-size: 1.25rem;
			grid-template-columns: 1fr 1fr 1fr;
		}
		.report-list .head>div {
			padding: 0.625rem 0;
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
		.report-list .head .select-nav::after {
			content: '';
			position: absolute;
			width: 10%;
			height: 0.125rem;
			background-color: #4284DC;
			bottom: 0;
		}
	}
</style>